import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicStorageModule } from '@ionic/storage';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { AngularFireDatabase, AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import * as firebase from 'firebase/app';
import { FormBuilder } from '@angular/forms';

export function createTranslateLoader(http:HttpClient){
  return new TranslateHttpLoader(http, 'assets/i18n/','.json');
}

export const config = {
  apiKey: "AIzaSyCEIORDQ-PJFfmKafbuWkLIw7fmmxk2CQc",
  authDomain: "la-carrera-panamericana-2df4b.firebaseapp.com",
  projectId: "la-carrera-panamericana-2df4b",
  storageBucket: "la-carrera-panamericana-2df4b.appspot.com",
  messagingSenderId: "558136502632",
  appId: "1:558136502632:web:c5806b6a832ea624c78edd",
  measurementId: "G-9MLNFVR0YS"
}


firebase.initializeApp(config);  

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule, 
    IonicModule.forRoot({rippleEffect: false}),
    TranslateModule.forRoot({
      loader:{
        provide :TranslateLoader,
        useFactory:(createTranslateLoader),
        deps: [HttpClient]
      } 
    }),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    IonicStorageModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AppRoutingModule,
    HttpClientModule,
  ],
  providers: [
    InAppBrowser,
    FormBuilder,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
