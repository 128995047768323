import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';

const LNG_KEY = 'SELECTED_LANGUAGE';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  selected = '';

  constructor(
    private translate : TranslateService,
    private storage : Storage,
    private ptl: Platform
  ) { }


  setInitialAppLanguage(){
    // let language = this.translate.getBrowserLang();
    // this.translate.setDefaultLang(language);

    this.storage.get(LNG_KEY).then(val =>{
      console.log(val)
      if(val){
        this.setLanguage(val);
        this.selected = val;
      }
      else{
        this.setLanguage("es");
        this.selected = "es"
      }
    })
  }

  getLanguage(){
    return[
      {text: 'English', value:'en', img:'assets/imgs/en.png'},
      {text: 'Spanish', value:'es', img:'assets/imgs/es.png'}
    ]
  }

  setLanguage(lng){
    console.log(lng)
    this.translate.use(lng);
    this.selected = lng;
    this.storage.set(LNG_KEY,lng)
  }

}
