import { Component } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { ApiService } from './services/api.service';
import { LanguageService } from './services/language.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private navCtrl: NavController,
    private api:ApiService,
    private idiomaService:LanguageService
  ) {

    this.initializeApp();
  }



  initializeApp() {
    this.platform.ready().then(() => {
      console.log("INITIALIZE APPP!!!!!")
      this.api.createHttpOptions();
      this.idiomaService.setInitialAppLanguage();
      // this.idiomaService.setInitialAppLanguage();
    });
    //firebase.initializeApp(firebaseConfig)
  }
}
