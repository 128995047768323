import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AccessGuard } from './guards/access.guard';
import { LoginGuard } from './guards/login.guard';
import { DataResolverService } from './resolver/data-resolver.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate:[LoginGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'lista-autos/:id',
    resolve: {
      special: DataResolverService
    },
    canActivate:[AccessGuard],
    loadChildren: () => import('./pages/lista-autos/lista-autos.module').then( m => m.ListaAutosPageModule)
  },
  {
    path: 'carnet/:id',
    resolve: {
      special: DataResolverService
    },
    canActivate:[AccessGuard],
    loadChildren: () => import('./pages/carnet/carnet.module').then( m => m.CarnetPageModule)
  },
  {
    path: 'registro-de-automovil/:id',
    resolve: {
      special: DataResolverService
    },
    canActivate:[AccessGuard],
    loadChildren: () => import('./pages/forms/registro-de-automovil/registro-de-automovil.module').then( m => m.RegistroDeAutomovilPageModule)
  },
  {
    path: 'datos-personales-pilotos/:id',
    resolve: {
      special: DataResolverService
    },
    canActivate:[AccessGuard],
    loadChildren: () => import('./pages/forms/datos-personales-pilotos/datos-personales-pilotos.module').then( m => m.DatosPersonalesPilotosPageModule)
  },
  {
    path: 'administrativo/:id',
    resolve: {
      special: DataResolverService
    },
    canActivate:[AccessGuard],
    loadChildren: () => import('./pages/forms/administrativo/administrativo.module').then( m => m.AdministrativoPageModule)
  },
  {
    path: 'reporte-medico/:id',
    resolve: {
      special: DataResolverService
    },
    canActivate:[AccessGuard],
    loadChildren: () => import('./pages/forms/reporte-medico/reporte-medico.module').then( m => m.ReporteMedicoPageModule)
  },
  {
    path: 'seguridad-uniforme/:id',
    resolve: {
      special: DataResolverService
    },
    canActivate:[AccessGuard],
    loadChildren: () => import('./pages/forms/seguridad-uniforme/seguridad-uniforme.module').then( m => m.SeguridadUniformePageModule)
  },
  {
    path: 'seguridad-auto/:id',
    resolve: {
      special: DataResolverService
    },
    canActivate:[AccessGuard],
    loadChildren: () => import('./pages/forms/seguridad-auto/seguridad-auto.module').then( m => m.SeguridadAutoPageModule)
  },
  {
    path: 'inspeccion-tecnica-auto/:id',
    resolve: {
      special: DataResolverService
    },
    canActivate:[AccessGuard],
    loadChildren: () => import('./pages/forms/inspeccion-tecnica-auto/inspeccion-tecnica-auto.module').then( m => m.InspeccionTecnicaAutoPageModule)
  },
  {
    path: 'servicio/:id',
    resolve: {
      special: DataResolverService
    },
    canActivate:[AccessGuard],
    loadChildren: () => import('./pages/forms/servicio/servicio.module').then( m => m.ServicioPageModule)
  },
  {
    path: 'aclaraciones',
    loadChildren: () => import('./pages/aclaraciones/aclaraciones.module').then( m => m.AclaracionesPageModule)
  },
  {
    path: 'penalizaciones',
    loadChildren: () => import('./pages/penalizaciones/penalizaciones.module').then( m => m.PenalizacionesPageModule)
  },
  {
    path: 'abandonos',
    loadChildren: () => import('./pages/abandonos/abandonos.module').then( m => m.AbandonosPageModule)
  },
  {
    path: 'boletines',
    loadChildren: () => import('./pages/boletines/boletines.module').then( m => m.BoletinesPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
