import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Storage } from '@ionic/storage';
import { DataService } from './data.service';

export interface ApiImage {
  _id: string;
  name: string;
  createdAt: Date;
  url: string;
}
 
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  url = environment.endpoint;
  token:any;
  httpOptions:any;

  constructor(
    private http: HttpClient,
    public storage: Storage,
    private dataService:DataService
     ) { }


  async createHttpOptions(){
    this.token = await this.dataService.getToken();
    console.log("token")
    console.log(this.token)
    if(this.token){
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': 'Bearer'+' '+this.token  
        })
      };
    }
    else{
      console.log("no hay usuario");
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
    }

    console.log(this.httpOptions);
   }


  ///POST///
  login(token){
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer'+' '+token,
      })
    };
    console.log(httpOptions);
    return this.http.post(`${this.url}/api/inicio-sesion`,{}, httpOptions);
  }


  // // Get carnet especifico
  // getCarnet(form,carnet){
  //   return this.http.post(`${this.url}/api/carnet/estado/${carnet}`,form, this.httpOptions);
  //   //carnets
  //   //registro-automovil
  //   //registro-piloto
  // }


  // Carnet administrativo copiloto
  administrativoCopiloto(form){
    return this.http.post(`${this.url}/api/carnet/estado/registro-administrativo`,form, this.httpOptions);
  }

   // registro administrativo 
  registroAdministrativo(form){
    return this.http.post(`${this.url}/api/registro-administrativo`,form, this.httpOptions);
  }

  // registro inspeccion tecnica 
  registroInspeccionT(form){
    return this.http.post(`${this.url}/api/registro-inspeccion-tecnica`,form, this.httpOptions);
  }

  getPermisos(form){
    console.log(this.httpOptions)
    return this.http.post(`${this.url}/admin/mis-permisos`,{}, this.httpOptions);
  }


  // registro medico 
  registroMedico(form){
    return this.http.post(`${this.url}/api/registro-medico`,form, this.httpOptions);
  }

  // registro seguridad automovil 
  registroSeguridadAuto(form){
    return this.http.post(`${this.url}/api/registro-seguridad-automovil`,form, this.httpOptions);
  }

  // registro seguridad uniforme 
  registroSeguridadUniforme(form){
    return this.http.post(`${this.url}/api/registro-seguridad-uniforme`,form, this.httpOptions);
  }

  // Obtener permisos
  obtenerPermisosCopiloto(form){
    return this.http.post(`${this.url}/api/permisos/de-copiloto`,form, this.httpOptions);
  }

  // Asignar permisos
  asignarPermisos(form){
    return this.http.post(`${this.url}/api/permisos/asignar`,form, this.httpOptions);
  }

  // Nuevo copiloto
  agregarCopiloto(form){
    return this.http.post(`${this.url}/api/nuevo-copiloto`,form, this.httpOptions);
  }

  // Eliminar copiloto
  eliminarCopiloto(form){
    return this.http.post(`${this.url}/api/eliminar-copiloto`,form, this.httpOptions);
  }

  // Registro autos
  registroAutomovil(form){
    return this.http.post(`${this.url}/api/registro-automovil`,form, this.httpOptions);
  }

  // Registro pilotos
  registroPiloto(form){
    return this.http.post(`${this.url}/api/registro-piloto`,form, this.httpOptions);
  }

  // Registro copilotos
  registroCopiloto(form){
    return this.http.post(`${this.url}/api/registro-copiloto`,form, this.httpOptions);
  }

  // Nuevo copiloto
  getCopilotos(form){
    return this.http.post(`${this.url}/api/obtener-copilotos`,form, this.httpOptions);
  }

   // agregar vehiculo servicio
   agregarVehiculoServicio(form){
    return this.http.post(`${this.url}/api/crear-vehiculo-servicio`,form, this.httpOptions);
  }

  // EDITAR vehiculo servicio
   editarVehiculoServicio(form){
    return this.http.post(`${this.url}/api/editar-vehiculo-servicio`,form, this.httpOptions);
  }

  // ELIMINAR vehiculo servicio
   eliminarVehiculoServicio(form){
    return this.http.post(`${this.url}/api/eliminar-vehiculo-servicio`,form, this.httpOptions);
  }

   // Obtener mecanicos de servicio piloto virtual
   obtenerMecanicosServicioPilotoVirtual(form){
    return this.http.post(`${this.url}/api/copiloto/obtener-mecanicos-servicio`,form, this.httpOptions);
  }

  // Obtener vehiculos de servicio piloto virtual
   obtenerVehiculosServicioPilotoVirtual(form){
    return this.http.post(`${this.url}/api/copiloto/obtener-vehiculos-servicio`,form, this.httpOptions);
  }


  // agregar mecanico servicio
   agregarMecanicoServicio(form){
    return this.http.post(`${this.url}/api/crear-mecanico-servicio`,form, this.httpOptions);
  }

  // EDITAR mecanico servicio
   editarMecanicoServicio(form){
    return this.http.post(`${this.url}/api/editar-mecanico-servicio`,form, this.httpOptions);
  }

  // ELIMINAR mecanico servicio
   eliminarMecanicoServicio(form){
    return this.http.post(`${this.url}/api/eliminar-mecanico-servicio`,form, this.httpOptions);
  }



  // Obtener piloto siendo copiloto
  getStatusCarnetPilotoCopiloto(form){
    return this.http.post(`${this.url}/api/copiloto/carnet-piloto`,form, this.httpOptions);
  }

  ///POST///


  ///GET///

  //ADMIN GET PILOTOS
  getPilotosTripulacion(){
    console.log(this.httpOptions)
    return this.http.get(`${this.url}/admin/todos-pilotos`,this.httpOptions);
  }

  getPilotosAdministrativo(){
    return this.http.get(`${this.url}/admin/todos-pilotos-administrativo`,this.httpOptions);
  }

  getPilotosMedico(){
    return this.http.get(`${this.url}/admin/todos-pilotos-medico`,this.httpOptions);
  }

  getEquiposSeguridad(){
    return this.http.get(`${this.url}/admin/equipo-seguridad`,this.httpOptions);
  }

  getInspeccionAuto(){
    return this.http.get(`${this.url}/admin/inspeccion-tecnica`,this.httpOptions);
  }

  getSeguridadPiloto(){
    return this.http.get(`${this.url}/admin/carnet/estado/seguridad-uniforme`,this.httpOptions);
  }

  getVehiculosServicioAdmin(){
    return this.http.get(`${this.url}/admin/vehiculos-servicio`,this.httpOptions);
  }

  getMecanicosServicioAdmin(){
    return this.http.get(`${this.url}/admin/mecanicos-servicio`,this.httpOptions);
  }

  getGeneral(){
    return this.http.get(`${this.url}/admin/estatus-todo`,this.httpOptions);
  }

  
  // Get carnet especifico
  getCarnet(form,carnet){
    return this.http.post(`${this.url}/admin/carnet/estado/${carnet}`,form, this.httpOptions);
    //carnets
    //registro-automovil
    //registro-piloto
  }



  //admin//

  // actualizar autos
  actualizarAuto(form){
    return this.http.post(`${this.url}/admin/formulario-automovil`,form, this.httpOptions);
  }


  // actualizar autos
  actualizarCopiloto(form){
    return this.http.post(`${this.url}/admin/formulario-copiloto`,form, this.httpOptions);
  }
  
  // actualizar autos
  actualizarPiloto(form){
    return this.http.post(`${this.url}/admin/formulario-piloto`,form, this.httpOptions);
  }
  
  // actualizar autos
  actualizarAdministrativo(form){
    return this.http.post(`${this.url}/admin/formulario-administrativo`,form, this.httpOptions);
  }


  actualizarMedico(form){
    return this.http.post(`${this.url}/admin/formulario-medico`,form, this.httpOptions);
  }

  actualizarSeguridadAuto(form){
    return this.http.post(`${this.url}/admin/formulario-seguridad-automovil`,form, this.httpOptions);
  }

  actualizarSeguridadUniforme(form){
    return this.http.post(`${this.url}/admin/formulario-seguridad-uniforme`,form, this.httpOptions);
  }

  actualizarInspeccionTecnica(form){
    return this.http.post(`${this.url}/admin/formulario-inspeccion-tecnica`,form, this.httpOptions);
  }


  register(form){
    // let httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type':  'application/json',
    //     'Authorization': 'Bearer'+' '+token,
    //   })
    // };
    return this.http.post(`${this.url}/admin/registro`,form ,this.httpOptions);
  }

  loginAdmin(form){
    console.log(form)
    return this.http.post(`${this.url}/admin/inicio-sesion`,form ,this.httpOptions);
  }








  //PILOTO
  getStatusCarnet(token){
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer'+' '+token,
      })
    };
    return this.http.get(`${this.url}/api/piloto/carnets`,httpOptions);
  }

    //PILOTO
  getStatusCarnetCopiloto(token){
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer'+' '+token,
      })
    };
    return this.http.get(`${this.url}/api/copiloto/carnets`,httpOptions);
  }
  

  getMarcas(){
    return this.http.get(`${this.url}/api/marcas`,this.httpOptions);
  }

  getVehiculosServicio(){
    return this.http.get(`${this.url}/api/obtener-vehiculos-servicio`,this.httpOptions);
  }

  getMecanicosServicio(){
    return this.http.get(`${this.url}/api/obtener-mecanicos-servicio`,this.httpOptions);
  }

  getAclaracionesTiempo(etapa){
    console.log(`${this.url}/admin/aclaraciones-tiempo?tipo=${etapa}`)
    return this.http.get(`${this.url}/admin/aclaraciones-tiempo?tipo=${etapa}`,this.httpOptions);
  }

  getReportesAbandono(tipo){
    return this.http.get(`${this.url}/admin/todos-reportes-abandono?tipo=${tipo}`,this.httpOptions);
  }

  getPenalizaciones(etapa){
    console.log(`${this.url}/admin/aclaraciones-penalizacion?tipo=${etapa}`)
    return this.http.get(`${this.url}/admin/aclaraciones-penalizacion?tipo=${etapa}`,this.httpOptions);
  }

  actualizarAclaracionTiempo(form){
    return this.http.post(`${this.url}/admin/actualizar-aclaracion-tiempo`,form, this.httpOptions);
  }

  actualizarReporteAbandono(form){
    return this.http.post(`${this.url}/admin/actualizar-reporte-abandono`,form, this.httpOptions);
  }

  actualizarAclaracionPenalizacion(form){
    return this.http.post(`${this.url}/admin/actualizar-aclaracion-penalizacion`,form, this.httpOptions);
  }

  ///GET///




 
  getImages() {
    return this.http.get<ApiImage[]>(`${this.url}/image`);
  }

  uploadImage(blobData, name, ext) {
    const formData = new FormData();
    formData.append('foto', blobData.imagen.base64String);
    console.log("data",blobData.base64String)

    let imagen = {
      foto : blobData.imagen.base64String,
      formato: blobData.imagen.format
    }
    return this.http.post(`${this.url}/api/usuarios/registro`, imagen,this.httpOptions);
  }
 
  uploadImageFile(file: File) {
    const ext = file.name.split('.').pop();
    const formData = new FormData();
    formData.append('foto',file);
    return this.http.post(`${this.url}/api/usuarios/registro`, formData,this.httpOptions);
  }
 
  deleteImage(id) {
    return this.http.delete(`${this.url}/image/${id}`,this.httpOptions);
  }

  registro(formulario){
    return this.http.post(`${this.url}/api/usuarios/registro`, formulario,this.httpOptions);
  }
}