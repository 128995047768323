import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree,Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(private storage:Storage, private router: Router){

  }

  async canActivate(){
    const isUserLoggedIn = await this.storage.get('authState');
    console.log("User login",isUserLoggedIn)

    const tipoUsuario = await this.storage.get('sesion');
    console.log("User login",tipoUsuario)

    if(isUserLoggedIn){
      if(tipoUsuario == "admin"){
        return true;
        //this.router.navigateByUrl('/tabs');
      }
      else if(tipoUsuario == "normal"){
        return true;
      }
      else if(tipoUsuario == "tecnico"){
        return true;
      }
      else if(tipoUsuario == "carnet"){

      }
      
    }
    else{
      console.log("aca")
        this.router.navigateByUrl('/login');
    } 
}


}
