import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class DataService {
 
  private data = [];
 
  constructor(
    private storage: Storage
  ) { }
 
  setData(id, data) {
    this.data[id] = data;
  }
 
  getData(id) {
    return this.data[id];
  }

  async getUser() {
    const user = await this.storage.get('uid');
    return user;
  }

  async getDataUser() {
    const user = await this.storage.get('user');
    return user;
  }

  async getToken() {
    const user = await this.storage.get('token');
    return user;
  }

  async getSesion(){
    const user = await this.storage.get('sesion');
    return user;
  }

  async getTipoTecnico() {
    const tipo = await this.storage.get('staff');
    return tipo;
  }
}