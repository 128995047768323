// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  endpoint:"https://lacarrerapanamericana.app/back",
  firebase: {
    apiKey: "AIzaSyCEIORDQ-PJFfmKafbuWkLIw7fmmxk2CQc",
    authDomain: "la-carrera-panamericana-2df4b.firebaseapp.com",
    projectId: "la-carrera-panamericana-2df4b",
    storageBucket: "la-carrera-panamericana-2df4b.appspot.com",
    messagingSenderId: "558136502632",
    appId: "1:558136502632:web:c5806b6a832ea624c78edd",
    measurementId: "G-9MLNFVR0YS"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
